import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import ImageCard from "./ImageCard"

const TeamContainer = ({ team }) => {

    return (
        <div className="container flex justify-center mb-12">
            {
                team.length > 0
                    ?
                    <div className="h-full w-full grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10 md:my-16">
                        {team.map((item, index) => {
                            return (
                                <div key={`landing-project-${index}`} >
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }} >
                                        <ImageCard content={item} />
                                    </motion.div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="container h-halfscreen flex items-center justify-center">
                        <h1 className="text-xl">No Team member is found</h1>
                    </div>
            }
        </div>
    )
}

export default TeamContainer
