import React from "react"
// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { motion } from "framer-motion"
// import { BrowserView, MobileView } from "react-device-detect";
// import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';




const LandingHero = ({ content }) => {
  const heroImage = getImage(content.heroImage)

  // const plainTextContent = content.companyValue

  // const Bold = ({ children }) => <span className="font-extrabold">{children}</span>;
  // const Italic = ({ children }) => <span className="italic text-xl">{children}</span>;

  // const options_plainText = {
  //   renderNode: {
  //     [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-2">{children}</p>,
  //   },
  //   renderMark: {
  //     [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  //     [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  //   },
  // }

  return (
    <BgImage
      image={heroImage}
      className="bg-blend-darken"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="h-screen w-full flex flex-col justify-center items-center">
        <div className="text-center">
          <h1 className="slogan text-5xl text-white lg:text-7xl font-semibold">SEEK <span className="text-themeyellow">BREVITY</span></h1>
          {content.tagline && content.tagline.map((line, index) => {
            return <h1 className="text-white text-lg lg:text-2xl font-normal" key={`landing-tagline-${index}`}>{line}</h1>
          })}
        </div>
      </motion.div>
    </BgImage>
  )
}

export default LandingHero
