import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import LandingHero from "../components/Landing/LandingHero"
import LandingAbout from "../components/Landing/LandingAbout"
import LandingThird from "../components/Landing/LandingThird"
import LandingProjects from '../components/Landing/LandingProjects'
import LandingTeam from '../components/Landing/LandingTeam'

const IndexPage = ({ data, location }) => {
  return (
    <Layout pathname={location} >
      <SiteMetadata
        title="Home"
        description="Brevity Studios | Seek Brevity"
        location={location.pathname}
      />
      <div className="#hero" id="">
        <LandingHero content={data.first} />
      </div>
      <div id="about">
        <LandingAbout content={data.about} />
      </div>
      <div>
        <LandingThird content={data.third} />
        <LandingProjects projects={data.projects.nodes[0].project} category={data.category.edges} />
        <LandingTeam heading={data.team.heading} description={data.team.description.description} members={data.team.members} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query LandingQuery {
    first: contentfulPageHero(title: {eq: "Landing > First"}) {
      tagline
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    about: contentfulPageHero(title: {eq: "Landing > About"}) {
      heroText
      tagline
      heroContent {
        heroContent
      }
    }
    third: contentfulPageHero(title: {eq: "Landing > Projects"}) {
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          quality: 80
        )
      }
      heroText
    }
    category: allContentfulCategory(sort: {order: ASC, fields: title}) {
      edges {
        node {
          slug
          title
        }
      }
    }
    projects: allContentfulProjectSequence {
      nodes {
        project {
          ...ProjectCard
        }
      }
    }
    team: contentfulHero3Images(title: {eq: "Landing > Team"}) {
      title
      heading
      description {
        description
      }
      members {
        team {
          ...ImageCard
        }
      }
    }
  }
`
