import React from 'react';


const LandingAbout = ({ content }) => {

    return (
        <div className="bg-custommist lg:h-full lg:flex lg:flex-row-reverse justify-center">
            <div className="w-full text-left mx-auto py-12 max-w-full border-t border-b border-black md:pb-24">
                <div className="container my-28 flex flex-col justify-between">
                    <h1 className="mb-12 text-5xl md:text-6xl">{content.heroText ? content.heroText : "WHAT WE DO"}</h1>
                    <div className="mb-4">
                        {
                            content.tagline ?
                                content.tagline.map((line, index) => <h1 key={`brevity-aboutus-${index}`} className="text-5xl md:text-6xl text-gray-600">{line}</h1>)
                                : <div></div>
                        }
                    </div>
                    <p className="md:-mb-16 text-[#573F3F]">{
                        content.heroContent ? content.heroContent.heroContent : "Brevity Studios is an award winning multi-disciplinary design studio specializing in architecture, interior design, product design, and branding & graphic design. Our design philosophy stems from a combination of functional pragmatism and whimsical playfulness with keen attention for details."
                    }</p>
                </div>
                <div id="projects"></div>
            </div>
        </div>
    );
};

export default LandingAbout;