import React from 'react';
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ImageCard = ({ content }) => {
    const image = getImage(content.team[0].portraits.media)
    return (
        <div className="flex flex-col md:flex-row">
            <div className="w-full text-center">
                <GatsbyImage objectFit="contain" image={image} alt={`${content.name}-image`} />
                <h3 className="text-sm md:text-lg my-4 font-bold">{content.team[0].name}</h3>
                <h3 className="text-sm md:text-lg my-4">{content.team[0].position}</h3>
            </div>
        </div>
    );
};

export default ImageCard;

export const query = graphql`
  fragment ImageCard on ContentfulTeam {
    name
    position
    portraits {
        media {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP],quality: 80)
            title
        }
    }
}
`