import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import ProjectCard from "./ProjectCard"

const ProjectContainer = ({ projects }) => {
    return (
        <div className="container flex justify-center my-12">
            {
                projects.length > 0
                    ?
                    <div className="h-full w-full grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 lg:gap-y-10 md:my-16">
                        {projects.map((project, index) => {
                            return (
                                <div key={`landing-project-${index}`} >
                                    <Link to={`/${project.slug}`}>
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 1 }} >
                                            <ProjectCard content={project} />
                                        </motion.div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="container h-halfscreen flex items-center justify-center">
                        <h1 className="text-xl">No Project is found</h1>
                    </div>
            }
        </div>
    )
}

export default ProjectContainer
