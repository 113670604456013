import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import TeamContainer from "../TeamContainer"




const LandingTeam = ({ heading, description, members }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="h-full lg:h-full">
      <div className="w-full text-left mx-auto py-12 max-w-full border-t border-black ">
        <div className="container mt-12 mb-8 flex flex-col justify-between">
          <h1 className="text-5xl md:text-6xl mb-12">{heading}</h1>
          <p className="small text-lg lg:text-md text-gray-600">{description}</p>
        </div>
      </div>
      <TeamContainer team={members} />
      <div id="contact"></div>
    </motion.div>
  )
}

export default LandingTeam
