import React from 'react';
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ProjectCard = ({ content }) => {
    const image = getImage(content.featuredImage)

    return (
        <div className="hover:opacity-75 flex flex-col md:flex-row transition duration-150 ease-in-out">
            <div className="w-full h-auto text-center">
                <GatsbyImage style={{ height: "30vh" }} objectFit="cover" image={image} alt={`${content.name}-image`} />
                <h3 className="text-sm md:text-lg my-4">{content.name}</h3>
            </div>
        </div>
    );
};

export default ProjectCard;

export const query = graphql`
  fragment ProjectCard on ContentfulProject {
    name
    slug
    category {
        slug
        title
    }
    description {
        raw
    }
    featuredImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
        file {
            url
        }
    }
    medias {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
        title
        file {
            contentType
            url
        }
    }
    seo {
        seoTitle
        description
        image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        hidePageFromSearchEnginesNoindex
        excludeLinksFromSearchRankingsNofollow
    }
    awards {
      awardImage {
        gatsbyImageData(
            width: 200,
            layout: CONSTRAINED, 
            placeholder: BLURRED, 
            formats: [AUTO, WEBP]
        ) 
      }
      links
    }
}
`