import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import ProjectContainer from "../ProjectContainer"




const LandingProjects = ({ projects, category }) => {
  const [filtered, setFiltered] = useState(projects)
  const [hasFocus, setFocus] = useState("all");

  const handleTabs = (item) => {
    const result = projects.filter(project => project.category.slug === item)
    setFiltered(result)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="h-full lg:h-full">
      <div className="w-screen border-t border-b border-black py-8">
        <div className="flex flex-col md:flex-row justify-between items-center container">
          <Link
            key={`category_link_allPosts}`}
            id="all-projects-tab"
            className={`uppercase text-sm sm:text-base font-medium text-black hover:text-gray-800 focus:text-gray-500 transition duration-150 ease-in-out ${hasFocus === "all" ? "text-gray-500" : "text-black"}`}
            to="#"
            onClick={e => { e.preventDefault(); setFiltered(projects); setFocus("all") }}
          >
            ALL
          </Link>
          {category && category.map((item, key) => (
            <Link
              key={`category_link${key}`}
              className={`uppercase text-sm sm:text-base font-medium text-black hover:text-gray-800 focus:text-gray-500 transition duration-150 ease-in-out ${hasFocus === item.node.slug ? "text-gray-500" : "text-black"}`}
              to="#"
              onClick={e => { e.preventDefault(); handleTabs(item.node.slug); setFocus(item.node.slug) }}
            >
              {item.node.title}
            </Link>
          ))}
        </div>
      </div>
      <ProjectContainer projects={filtered} />
      <div id="team"></div>
    </motion.div>
  )
}

export default LandingProjects
