import React from "react"
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { motion } from "framer-motion"
import { BrowserView, MobileView } from "react-device-detect";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';




const LandingThird = ({ content }) => {
  const heroImage = getImage(content.heroImage)


  return (
    <BgImage
      image={heroImage}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="h-halfscreen w-full flex flex-col justify-center items-center">
        <div className="text-center text-xs lg:text-normal">
          {content.heroText && <h2 className="text-themeyellow ">{content.heroText}</h2>}
        </div>
      </motion.div>
    </BgImage>
  )
}

export default LandingThird
